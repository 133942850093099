import { Icon } from "@iconify/react";

function App() {
  return (
    <div className="Container">
      <p className="Title Righteous" >
        Budget <span className="Cat">Cat</span>
      </p>
      <p className="Coming">Coming Soon</p>

      <h2 className="Learn">Learn More</h2>
      <div className="SocialMedia">
        <Icon
          className="App"
          icon="bxl:tiktok"
          width="55"
          height="55"
          onClick={() => window.open("https://www.tiktok.com/@budgetcatapp")}
        />
        <Icon
          className="App"
          icon="mdi:twitter"
          width="55"
          height="55"
          onClick={() => window.open("https://twitter.com/budgetcatapp")}
        />
      </div>
    </div>
  );
}

export default App;
